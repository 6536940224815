<template>
  <div class="renyuan">
    <Head></Head>
    <div class="content">
      <div class="left">
        <div class="select-item" v-for="(item, index) in itemList" :key="index">
          <div
            class="item"
            :class="[item.isSelected ? 'is-select-bg' : 'no-select-bg']"
            @click="select(item)"
          >
            <span>{{ item.name }}</span>
          </div>
          <div class="item-tips" v-if="item.isSelected">
            <span>{{ item.desc }}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="swiper">
          <el-carousel :interval="4000" type="" height="84vh">
            <el-carousel-item
              style="width: 100%"
              v-for="(item, index) in selectItem.url"
              :key="index"
            >
              <div class="images">
                <img :src="item.src" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
export default {
  name: "renyuan",
  components: { Head, Foot },
  data() {
    return {
      selectItem: {},
      itemList: [
        {
          name: "领导关怀",
          value: "1",
          desc: "文旅大数据指挥中心运行以来，甘肃省委、省政府、省人大常委会等各级领导调研指导。",
          isSelected: true,
          url: [{ src: require("../../image/laifang/lingdao.png") }],
        },
        {
          name: "省区交流",
          value: "2",
          desc: "2018年以来，山西、江西、四川、新疆、浙江、湖北、辽宁、青海等多个兄弟省份文旅部门参观交流。",
          isSelected: false,
          url: [{ src: require("../../image/laifang/shengqu.png") }],
        },
        {
          name: "部门合作",
          value: "3",
          desc: "先后与省交通厅、省科技厅、省计算中心、省档案馆等单位合作交流，并就相关业务达成共识。",
          isSelected: false,
          url: [{ src: require("../../image/laifang/bumen.png") }],
        },
        {
          name: "平台共建",
          value: "4",
          desc: "携程、美团、途牛等多个头部OTA平台，建设银行甘肃分行、甘肃公航旅集团、甘肃文旅集团等有关企业负责人前来交流学习。",
          isSelected: false,
          url: [{ src: require("../../image/laifang/pingtai.png") }],
        },
      ],
    };
  },
  mounted() {
    this.selectItem = this.itemList.filter((item) => {
      return item.value == this.$route.query.id;
    })[0];
    this.select(this.selectItem);
  },
  methods: {
    select(item) {
      this.itemList.forEach((item) => {
        item.isSelected = false;
      });
      item.isSelected = true;
      this.selectItem = item;
    },
    goPrev: function () {
      this.$router.push({ path: "/achievements" });
    },
  },
};
</script>

<style lang="scss" scoped>
.renyuan {
  height: 100vh;
  .content {
    display: flex;
    height: 85vh;
  }
  .left {
    width: 18vw;
    background: #16295e;
    padding: 4vh 2vw;
  }
}

.select-item {
  margin-bottom: 4vh;
  .item {
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
    font-size: 1.4vw;
    padding: 2vh;
  }
  .item-tips {
    text-align: justify;
    font-size: 22px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    padding: 2vh 0;
  }
  .no-select-bg {
    background: #213d8d;
  }
  .is-select-bg {
    background: #3574c5;
  }
}
.right {
  width: 82vw;
  background: url("../../image/laifang/bg.png") no-repeat center;
  background-size: 100% 100%;
}
.swiper {
  height: 100%;
  padding: 1vh 5vw;
}
.images {
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
  }
  // width: 1000px;
  /*height: 900px;*/
  //   height: 100%;
}
.el-carousel__item:nth-child(1) {
  height: 1000px;
}
.el-carousel__item:nth-child(2n) {
  /*background: transparent;*/
  background-color: transparent;
}

.el-carousel__item:nth-child(2n + 1) {
  /*background: transparent;*/
  background-color: transparent;
}
</style>
